import moment, {Moment} from 'moment'
import Task, {taskConverter} from '../../db/Task/model'
import {TASKS_COLLECTION_NAME} from '../../db/Task/index'
import {userDocument} from '../../db/User/index'

export function submitTask({
	task,
	userId,
}: {
	userId: string
	task: {name: string; durationMin: number; date: Moment}
}) {
	return (
		userDocument(userId)
			.collection(TASKS_COLLECTION_NAME)
			// Can not call add([classInstance]) because firebase will call an exception.
			// This can not register this converter since it will enforce the type of argument provided to add
			// function to be an instance of Task class. See: https://github.com/firebase/firebase-js-sdk/issues/311
			// .withConverter(taskConverter)
			.add(taskConverter.toFirestore(new Task(task)))
	)
}

export function tasksForTheDay({userId, day}: {userId: string; day: Moment}) {
	return userDocument(userId)
		.collection(TASKS_COLLECTION_NAME)
		.withConverter(taskConverter)
		.where('date', '>=', day.startOf('day').toDate())
		.where(
			'date',
			'<',
			moment(day)
				.add('1', 'day')
				.toDate()
		)
}

export function updateCompleted({
	userId,
	taskId,
	newCompletedMin,
}: {
	userId: string
	taskId: string
	newCompletedMin: number
}) {
	return userDocument(userId)
		.collection(TASKS_COLLECTION_NAME)
		.withConverter(taskConverter)
		.doc(taskId)
		.update({completedMin: newCompletedMin})
}

export function deleteTask({userId, taskId}: {userId: string; taskId: string}) {
	return userDocument(userId)
		.collection(TASKS_COLLECTION_NAME)
		.withConverter(taskConverter)
		.doc(taskId)
		.delete()
}
