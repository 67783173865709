import React, {useState} from 'react'
import {Container, makeStyles, createStyles} from '@material-ui/core'
import moment, {Moment as MomentType} from 'moment'
import DaySelect from './components/DaySelect'
import TasksList from './components/TasksList'
import TaskInput from './components/TaskInput'

const useStyles = makeStyles(() =>
	createStyles({
		root: {},
	})
)

function TaskList({className}: {className: string}) {
	const classes = useStyles()
	const [date, setDate] = useState<MomentType>(moment().startOf('day'))
	return (
		<Container className={`${classes.root} ${className}`} maxWidth="md">
			<DaySelect value={date} onChange={setDate} />
			<TaskInput date={date} />
			<TasksList selectedDate={date} />
		</Container>
	)
}

export default TaskList
