import React, {useState, useEffect} from 'react'
import {
	CssBaseline,
	Box,
	makeStyles,
	ThemeProvider,
	createMuiTheme,
	CircularProgress,
} from '@material-ui/core'
import {SnackbarProvider} from 'notistack'
import {User, auth} from 'firebase'
import RootRouter from './components/RootRouter'

const theme = createMuiTheme({
	// @ts-ignore
	warningColor: 'darkred',
})

const useStyles = makeStyles(() => ({
	rootBox: {
		minHeight: '100vh',
	},
	loader: {
		minHeight: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

function App() {
	const classes = useStyles()
	const [user, setUser] = useState<User | null | undefined>(undefined)

	useEffect(() => {
		return auth().onAuthStateChanged(setUser)
	}, [])

	return (
		<ThemeProvider theme={theme}>
			<Box className={classes.rootBox}>
				<SnackbarProvider
					maxSnack={5}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<CssBaseline />
					{user === undefined ? (
						<Box className={classes.loader}>
							<CircularProgress />
						</Box>
					) : (
						<RootRouter />
					)}
				</SnackbarProvider>
			</Box>
		</ThemeProvider>
	)
}

export default App
