import React, {useState, FormEvent} from 'react'
import {TextField, Button} from '@material-ui/core'
import {Moment} from 'moment'
import {auth} from 'firebase'
import {submitTask} from '../db'

function TaskInput({date}: {date: Moment}) {
	const [name, setName] = useState('')
	const [durationMin, setDurationMin] = useState<number>(30)

	function onFormSubmitted(e: FormEvent<HTMLFormElement>) {
		e.preventDefault()

		const normalizedName = name.trim()
		if (!normalizedName) return

		const userId = auth().currentUser?.uid
		if (!userId) return

		submitTask({
			userId,
			task: {name: normalizedName, durationMin, date},
		})

		setName('')
		setDurationMin(30)
	}

	return (
		// TODO move styles to appropriate place
		<form
			style={{marginTop: '2rem', display: 'flex'}}
			onSubmit={onFormSubmitted}
		>
			<TextField
				style={{flex: '1'}}
				variant="standard"
				value={name}
				placeholder="Task name"
				onChange={e => setName(e.target.value)}
			/>
			<TextField
				style={{width: '3rem'}}
				variant="standard"
				value={durationMin}
				type="number"
				placeholder="Estimated duration"
				InputLabelProps={{
					shrink: true,
				}}
				onChange={e => setDurationMin(Math.max(Number(e.target.value), 0))}
			/>
			<Button color="primary" variant="contained" type="submit">
				Submit
			</Button>
		</form>
	)
}

export default TaskInput
