import React from 'react'
import {BrowserRouter, Switch} from 'react-router-dom'
import AuthSensitiveRouter from '../utils/AuthSensitiveRoute'
import LoginScreen from './LoginScreen'
import AppScreen from './AppScreen'

function RootRouter() {
	return (
		<BrowserRouter>
			<Switch>
				<AuthSensitiveRouter path="/login" mode="onlyNotLogged">
					<LoginScreen />
				</AuthSensitiveRouter>
				<AuthSensitiveRouter path="/" mode="onlyLogged">
					<AppScreen />
				</AuthSensitiveRouter>
			</Switch>
		</BrowserRouter>
	)
}

export default RootRouter
