import React from 'react'
import {auth} from 'firebase'
import {
	Typography,
	Paper,
	TextField,
	makeStyles,
	createStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import TaskModel from '../../../db/Task/model'
import {updateCompleted, deleteTask} from '../db'

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'baseline',
			padding: '.5rem 1rem',
			marginBottom: '.5rem',
		},
		taskName: {
			flex: 1,
		},
		duration: {
			marginLeft: '1rem',
			fontSize: '.85rem',
		},
		left: {
			marginLeft: '1rem',
			fontSize: '.85rem',
		},
		doneField: {
			width: '3rem',
			marginLeft: '1rem',
		},
		deleteIcon: {
			marginLeft: '1rem',
			alignSelf: 'center',
			fontSize: '1.2rem',
			cursor: 'pointer',
		},
	})
)

interface Props {
	taskData: TaskModel
}

function Task({taskData}: Props) {
	const classes = useStyles()

	return (
		<Paper
			style={{opacity: taskData.isFinished ? 0.75 : 1}}
			className={classes.container}
		>
			<Typography className={classes.taskName}>{taskData.name}</Typography>
			<Typography className={classes.duration}>
				Duration: {taskData.durationMin}
			</Typography>
			<Typography className={classes.left}>
				Left: {taskData.timeLeft}
			</Typography>
			<TextField
				className={classes.doneField}
				variant="standard"
				onChange={e => {
					const userId = auth().currentUser?.uid
					if (!userId) return
					if (!taskData.id) return

					const newCompletedValue = Math.max(
						Math.min(Number(e.target.value), taskData.durationMin),
						0
					)
					updateCompleted({
						userId,
						taskId: taskData.id,
						newCompletedMin: newCompletedValue,
					})
				}}
				value={taskData.completedMin}
				type="number"
			/>
			<DeleteIcon
				className={classes.deleteIcon}
				onClick={() => {
					const userId = auth().currentUser?.uid
					if (!userId) return
					if (!taskData.id) return

					deleteTask({userId, taskId: taskData.id})
				}}
			/>
		</Paper>
	)
}

export default Task
