import React from 'react'
import {Box, makeStyles, createStyles} from '@material-ui/core'
import AppBar from './AppBar'
import TaskList from './TasksList'

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			minHeight: '100vh',
			display: 'flex',
		},
		contentContainer: {
			flex: 1,
		},
		taskList: {maxWidth: '50rem'},
	})
)
function AppScreen() {
	const classes = useStyles()
	return (
		<Box className={classes.root}>
			<AppBar />
			<Box className={classes.contentContainer}>
				<TaskList className={classes.taskList} />
			</Box>
		</Box>
	)
}

export default AppScreen
