import React from 'react'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

const firebaseConfig = {
	apiKey: 'AIzaSyB59ClpnDc6lriT7otGIUfissjjSAFaYcs',
	authDomain: 'day-planner-app.firebaseapp.com',
	databaseURL: 'https://day-planner-app.firebaseio.com',
	projectId: 'day-planner-app',
	storageBucket: 'day-planner-app.appspot.com',
	messagingSenderId: '426869278783',
	appId: '1:426869278783:web:969662736c560a40fc108a',
	measurementId: 'G-EY1ZGGY7V8',
}

firebase.initializeApp(firebaseConfig)
firebase.firestore()
firebase.analytics()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
