import React from 'react'
import {makeStyles, createStyles, Paper} from '@material-ui/core'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import SettingsIcon from '@material-ui/icons/Settings'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import {auth} from 'firebase'

const useStyles = makeStyles(() =>
	createStyles({
		appBar: {
			width: '5rem',
			borderRadius: 0,
			padding: '1rem',
			display: 'flex',
			flexDirection: 'column',
		},
		mainSection: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
		},
		bottomSection: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		exitIcon: {
			cursor: 'pointer',
			marginTop: '1rem',
		},
		icon: {
			color: '#111111',
			fontSize: '2rem',
		},
	})
)
function AppBar() {
	const classes = useStyles()

	return (
		<Paper className={classes.appBar}>
			<div className={classes.mainSection}>
				<PlaylistAddCheckIcon style={{fontSize: '3rem'}} />
			</div>
			<div className={classes.bottomSection}>
				<SettingsIcon className={classes.icon} />
				<ExitToAppIcon
					className={`${classes.icon} ${classes.exitIcon}`}
					onClick={() => auth().signOut()}
				/>
			</div>
		</Paper>
	)
}

export default AppBar
