import moment, {Moment} from 'moment'
import {firestore} from 'firebase'

export interface TaskData {
	id?: string
	name: string
	durationMin: number
	date: Moment
	completedMin?: number
}

export default class Task {
	readonly id: string | null
	readonly name: string
	readonly durationMin: number
	readonly date: Moment
	readonly completedMin: number

	constructor({id, name, durationMin, date, completedMin}: TaskData) {
		this.id = id || null
		this.name = name
		this.durationMin = durationMin
		this.date = date
		this.completedMin = completedMin || 0
	}

	get isFinished(): boolean {
		return this.completedMin >= this.durationMin
	}

	get timeLeft(): number {
		return this.durationMin - this.completedMin
	}
}

export const taskConverter = {
	toFirestore({
		name,
		durationMin,
		date,
		completedMin,
	}: Task): firestore.DocumentData {
		return {
			name,
			durationMin,
			date: date.toDate(),
			completedMin,
		}
	},

	fromFirestore(
		snapshot: firestore.QueryDocumentSnapshot,
		options: firestore.SnapshotOptions
	): Task {
		const data = snapshot.data(options)
		return new Task({
			id: snapshot.id,
			name: data.name,
			durationMin: data.durationMin,
			date: moment(data.date),
			completedMin: data.completedMin || 0,
		})
	},
}
