import React, {useEffect, useState} from 'react'
import moment, {Moment} from 'moment'
import {auth} from 'firebase'
import {Typography, makeStyles, createStyles} from '@material-ui/core'
import {tasksForTheDay} from '../db'
import TaskComponent from './Task'
import Task from '../../../db/Task/model'

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			marginTop: '2rem',
		},
	})
)
interface Props {
	selectedDate: Moment
}

function getDateToStartCountFrom(taskDate: Moment) {
	const today = moment()
	if (taskDate.isSame(today, 'day')) return moment()
	return taskDate.startOf('day').add(6, 'hours')
}

function TasksList({selectedDate}: Props) {
	const [data, setData] = useState<Task[]>([])
	const classes = useStyles()

	useEffect(() => {
		const userId = auth().currentUser?.uid
		if (!userId) return

		const unsubscribe = tasksForTheDay({userId, day: selectedDate}).onSnapshot(
			query => {
				setData(query.docs.map(one => one.data()))
			}
		)

		return unsubscribe
	}, [selectedDate])

	const {finishedTasks, tasksInProgress} = data.reduce<{
		finishedTasks: Task[]
		tasksInProgress: Task[]
	}>(
		(acc, oneTask) => {
			if (oneTask.isFinished) acc.finishedTasks.push(oneTask)
			else acc.tasksInProgress.push(oneTask)
			return acc
		},
		{finishedTasks: [], tasksInProgress: []}
	)

	const totalTaskDurationMin = tasksInProgress.reduce(
		(acc, val) => acc + val.durationMin - val.completedMin,
		0
	)

	return (
		<div className={classes.root}>
			<div>
				{tasksInProgress.map((one: any) => (
					<TaskComponent key={one.id} taskData={one} />
				))}
			</div>
			<div>
				{finishedTasks.map((one: any) => (
					<TaskComponent key={one.id} taskData={one} />
				))}
			</div>
			{data.length === 0 && (
				<Typography>No tasks added for this day</Typography>
			)}
			{data.length > 0 && (
				<div>
					<Typography>
						Total time left{' '}
						<b>
							{totalTaskDurationMin / 60}h {totalTaskDurationMin % 60}min
						</b>
					</Typography>
					<Typography>
						Day ends at{' '}
						<b>
							{getDateToStartCountFrom(selectedDate)
								.add(totalTaskDurationMin, 'minutes')
								.format('HH:mm')}
						</b>
					</Typography>
				</div>
			)}
		</div>
	)
}

export default TasksList
