import React, {useState, useEffect} from 'react'
import {Route, Redirect, RouteProps} from 'react-router-dom'
import {User, auth} from 'firebase'

interface Props extends RouteProps {
	mode: 'onlyLogged' | 'onlyNotLogged'
}

function AuthSensitiveRouter({mode, children, ...rest}: Props) {
	const [user, setUser] = useState<User | null | undefined>(undefined)

	useEffect(
		() =>
			auth().onAuthStateChanged(newUser => {
				setUser(newUser)
			}),
		[]
	)
	return (
		<>
			<Route
				render={() => {
					if (mode === 'onlyLogged') {
						if (!!user) return children
						return <Redirect to="/login" />
					}

					if (mode === 'onlyNotLogged') {
						if (!user) return children
						return <Redirect to="/" />
					}

					return children
				}}
				{...rest}
			/>
		</>
	)
}

export default AuthSensitiveRouter
