import React from 'react'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment, {Moment} from 'moment'
import {createStyles, makeStyles, Paper, Box} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		datePicker: {},
		pickerContainer: {
			padding: '.5rem',
		},
		navigateIcon: {
			padding: '.5rem',
			fontSize: '3.5rem',
		},
	})
)
interface Props {
	value: Moment
	onChange: (value: Moment) => void
}

function DaySelect({value, onChange}: Props) {
	const classes = useStyles()
	const handleDateChange = (date: any) => {
		if (date) onChange(moment(date).startOf('day'))
	}

	const handleNext = () => {
		onChange(moment(value).add(1, 'day'))
	}

	const handleBack = () => {
		onChange(moment(value).add(-1, 'day'))
	}

	return (
		<Box className={classes.root}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<Paper elevation={1} className={classes.pickerContainer}>
					<NavigateBeforeIcon
						onClick={handleBack}
						className={classes.navigateIcon}
					/>
					<KeyboardDatePicker
						disableToolbar
						variant="inline"
						format="DD. MMMM YYYY"
						margin="normal"
						value={value}
						onChange={handleDateChange}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
					<NavigateNextIcon
						onClick={handleNext}
						className={classes.navigateIcon}
					/>
				</Paper>
			</MuiPickersUtilsProvider>
		</Box>
	)
}

export default DaySelect
