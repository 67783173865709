import React from 'react'
import {auth} from 'firebase/app'
import {Container, makeStyles, Typography} from '@material-ui/core'
import GoogleButton from 'react-google-button'
import {useSnackbar} from 'notistack'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'

const provider = new auth.GoogleAuthProvider()

const useStyles = makeStyles(theme => ({
	root: {
		minHeight: '100vh',
		display: ' flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
	},
	title: {
		marginBottom: theme.spacing(4),
	},
	logo: {
		fontSize: '7rem',
		marginBottom: '2rem',
	},
}))

function LoginScreen() {
	const classes = useStyles()
	const {enqueueSnackbar} = useSnackbar()

	async function login() {
		try {
			auth().setPersistence(auth.Auth.Persistence.LOCAL)
			await auth().signInWithPopup(provider)
		} catch (err) {
			console.log('Error while logging in', err)
			enqueueSnackbar('Error while logging you in. Please try again', {
				variant: 'error',
			})
		}
	}

	return (
		<Container className={classes.root}>
			<PlaylistAddCheckIcon className={classes.logo} />
			<Typography className={classes.title} variant="h3" component="h1">
				Welcome to Day Planner app
			</Typography>
			<GoogleButton onClick={login} />
		</Container>
	)
}

export default LoginScreen
